@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Bootstrap */

/* Fonts */

/* 
@import './assets/fonts/Nunito-Italic.ttf';
@import './assets/fonts/Nunito-Variable.ttf'; */


/* Main theme */

:root {
    --coffee: #2B2727;
    --creame: #FEDD80;
    --vanilla: #FFF2CA;
    --creamer: #FFDC7F;
}


/* Colors */

.coffee {
    color: #2B2727;
    color: var(--coffee);
}

.creame {
    color: #FEDD80;
    color: var(--creame);
}

.vanilla {
    color: #FFF2CA;
    color: var(--vanilla);
}

.creamer {
    color: #FFDC7F;
    color: var(--creamer);
}


/* Backgrounds */

.coffee-bg {
    background-color: #2B2727;
    background-color: var(--coffee);
}

.creame-bg {
    background-color: #FEDD80;
    background-color: var(--creame);
}

.vanilla-bg {
    background-color: #FFF2CA;
    background-color: var(--vanilla);
}

.creamer-bg {
    background-color: #FFDC7F;
    background-color: var(--creamer);
}

*,
ul,
li,
a,
ul>li>a {
    font-family: 'Nunito', sans-serif;
    text-decoration: none;
    list-style: none;
    outline: none;
}

button {
    background-color: transparent;
    border: none;
}

html,
body {
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}

.App {
    width: 100%;
    height: 100%;
}

.content {
    z-index: 2;
    width: 100%;
    height: 100%;
    position: absolute;
}

.theme {
    background-color: #FFF2CA;
    background-color: var(--vanilla);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.backdrop {
    background-color: #2B2727;
    background-color: var(--coffee);
    transition: .2s all ease-in;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
}

.fs-55 {
    font-size: 1.2rem;
}
